@font-face {
	font-family: 'zihun58';
	src: url('../fonts/zihun58-ss.woff2') format('woff2'),
	url('../fonts/zihun58-ss.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

/* Base styles */

html,
body {
	height: 100%;
	width: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
}

html {
	font-family: 'zihun58', '华文细黑', 'STXihei', 'PingFang TC', '微软雅黑体', 'Microsoft YaHei New', '微软雅黑', 'Microsoft Yahei', '宋体', 'SimSun', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
	color: #393333;
	font-size: 1em;
	line-height: 1.4;
}

::-moz-selection {
	background: #393333;
	color: #fff;
	text-shadow: none;
}

::selection {
	background: #393333;
	color: #fff;
	text-shadow: none;
}

img {
	height: auto;
	width: 100%;
}

.clearfix::before,
.clearfix::after {
	content: " ";
	display: table;
}

.clearfix::after {
	clear: both;
}

/* Main styles */

.main {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	max-width: 1920px;
	height: 100vh;
	margin: 0 auto;
	padding: 30px 25px;
	background: white;
	overflow: hidden;
	box-sizing: border-box;
}

.column-primary {
	flex: 0 1 auto;
	width: 100%;
	padding-top: 0;
	text-align: center;
	order: -1;
}

.column-secondary {
	position: relative;
	width: 100%;
	flex: 1 1 auto;
	overflow: hidden;
	order: 0;
}

.logo,
.intro,
.logo-mobile,
.intro-mobile {
	display: none;
}

.logo {
	text-align: center;
}

.logo img {
	width: 160px;
}

.intro {
	font-size: 16px;
	line-height: 1.5;
	margin-bottom: 0;
}

.title {
	width: 60%;
	margin: 20px auto 10px;
	padding: 6px 0;
	background: #393333;
	color: #fff;
	font-size: 15px;
}

.subtitle {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 40px;
	margin: 0 0 10px;
	font-size: 10px;
}

.button-row {
	margin: 10px 0 0;
}

.button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	width: 46px;
	height: 46px;
	padding: 5px;
	margin: 0 6px;
	border-radius: 50%;
	background: none;
	border: 1px solid #393333;
	cursor: pointer;
}

.button:hover {
	opacity: 0.85;
}

.button img {
	max-width: 22px;
}

.button:focus {
	outline: 0;
}

.wechat-dialog,
.map-dialog {
	display: flex;
	align-items: center;
	justify-content: center;
}

.vex-content {
	position: relative;
	margin: 30px;
	text-align: center;
	padding-top: 70px;
}

.vex-close {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 40px;
	padding: 10px;
	cursor: pointer;
}

.vex-close::before {
	display: block;
	width: 40px;
	height: 40px;
	line-height: 40px;
}

.vex-dialog-buttons {
	display: none;
}

.dialog-content {
	margin: 20px 50px 40px;
	max-width: 400px;
}

.media-container {
	background-repeat: no-repeat;
	background-position: center bottom;
	background-size: cover;
}

.media-container picture {
	display: none;
}

/* Responsive Design */

@media only screen and (max-width: 899px) {

	body {
		overflow: auto;
	}

	.main {
		height: 100%;
	}

	.column-primary {
		display: flex;
		justify-content: space-between;
		padding-top: 30px;
		padding-bottom: 0;
		order: 1;
	}

	.column-secondary {
		display: flex;
		flex-direction: column;
		align-items: stretch;
	}

	.logo-mobile {
		display: block;
		margin: 0 auto 10px;
		width: 100%;
		max-width: 130px;
		flex: 0 1 auto;
	}

	.media-container {
		flex: 1 0 auto;
	}

	.contact-section {
		flex: 1;
		margin-right: 10px;
	}

	.contact-section + .contact-section {
		margin-right: 0;
		margin-left: 10px;
	}

	.intro-mobile {
		display: block;
		width: 80%;
		font-size: 15px;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		text-align: center;
	}

	.title {
		width: auto;
		margin-top: 0;
		font-size: 14px;
	}

}

@media only screen and (min-width: 360px) {

	.button {
		width: 46px;
		height: 46px;
		margin: 0 6px;
	}

	.intro-mobile {
		bottom: 0;
	}

}

@media only screen and (min-width: 375px) {

	.subtitle {
		font-size: 13px;
	}

}

@media only screen and (min-width: 460px) {

	.intro-mobile {
		bottom: 0;
	}

}

@media only screen and (min-width: 520px) {

	.subtitle {
		margin: 0;
	}

	.intro-mobile {
		bottom: 20px;
	}

}

@media only screen and (min-width: 900px) {

	.main {
		flex-direction: row;
		flex-wrap: nowrap;
		padding: 60px;
	}

	.column-primary {
		height: 100%;
		box-sizing: border-box;
		justify-content: center;
		display: flex;
		flex-direction: column;
		flex: 0 0 320px;
		padding-right: 40px;
		order: 1;
	}

	.column-secondary {
		height: auto;
		flex: 1 0 0;
		order: 2;
		overflow: hidden;
	}

	.title {
		font-size: 16px;
	}

	.logo {
		display: block;
	}

	.logo img {
		width: 160px;
		margin-bottom: 15px;
	}

	.intro {
		display: block;
	}

	.button {
		margin: 0 10px;
	}

	.media-container {
		height: 100%;
		background: none !important;
	}

	.media-container picture {
		display: initial;
	}

}

@media only screen and (min-height: 640px) and (min-width: 900px) {

	.title {
		margin-top: 30px;
	}

}

@media only screen and (min-height: 700px) and (min-width: 900px) {

	.column-primary {
		padding: 40px 40px 40px 0;
	}

	.intro {
		margin-top: 40px;
	}

	.title {
		margin-top: 40px;
	}

}

@media only screen and (min-width: 1200px) {

	.column-primary {
		flex: 0 0 400px;
		padding-right: 50px;
	}

}

@media only screen and (max-width: 900px) and (max-height: 599px) {

	.media-container {
		height: 340px;
		background-position: center 80%;
	}

}

@media only screen and (max-width: 900px) and (max-height: 700px) {

	.main {
		overflow: auto;
		height: auto;
	}

	.column-secondary {
		overflow: auto;
	}

}

@media only screen and (max-width: 900px) and (min-height: 600px) and (max-height: 700px) {

	.media-container {
		height: 380px;
	}

}

@media only screen and (max-width: 900px) and (max-height: 800px) {

	.media-container {
		background-position: center 80%;
	}

}
